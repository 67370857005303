import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { MDBCol, MDBRow, MDBInput, MDBTextArea } from "mdb-react-ui-kit";

import Button from "react-bootstrap/Button";
import me from "../images/personleft.jpg";

import { motion } from "framer-motion";

async function AddUser({ nameC, phoneC, messageC }) {
  await axios.post(`api/users`, {
    username: nameC,
    phone: phoneC,
    form_message: messageC,
  });
  window.location.reload(false);
}

const Hero = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });

  const [nameC, setNameC] = useState("");

  const handleNameCChange = (event) => {
    setNameC(event.target.value);
  };
  //PhoneCState
  const [phoneC, setPhoneC] = useState("");

  const handlePhoneCChange = (event) => {
    setPhoneC(event.target.value);
  };
  //MessageCState
  const [messageC, setMessageC] = useState("");

  const handleMessageCChange = (event) => {
    setMessageC(event.target.value);
  };
  return (
    <>
      <div
        className=" position-absolute"
        style={{
          height: 500,
          width: width > 1400 ? "60vw" : "70vw",
          top: 170,
          left: width > 1400 ? "20vw" : "15vw",
          marginTop: 0,
          borderRadius: 30,
        }}
      >
        <h1
          style={{
            marginTop: -75,
            marginLeft: width > 768 ? -100 : 0,
            width: "75vw",
          }}
          className="text-fourth  simple-title text-uppercase fw-semibold text-center "
        >
          Узнайте <span className=" fw-bolder">секрет</span> к жизни{" "}
          <span className=" text-decoration-underline ">без</span> дискомфорта в
          стопах
        </h1>
        <Container>
          <Row className="mt-5">
            <Col
              className={
                width > 768
                  ? "bg-third rounded-start-5 p-4"
                  : "bg-third rounded-top-5 p-4"
              }
            >
              <Container>
                <Row>
                  <h2 className=" playful-regular">
                    Выбирайте{" "}
                    <span className=" fw-semibold text-decoration-underline ">
                      профессионалов
                    </span>
                    <br />
                    <span style={{ fontSize: 20 }}>
                      Узнайте как я смогу вам помочь:
                    </span>
                  </h2>
                </Row>
                <Row>
                  <MDBInput
                    placeholder="Имя"
                    id="form3FirstName"
                    className="mb-3 mt-3"
                    onChange={handleNameCChange}
                    required
                  />
                  <MDBInput
                    placeholder="Телефон"
                    id="form3Email"
                    className="mb-3"
                    onChange={handlePhoneCChange}
                    required
                  />
                  <MDBTextArea
                    placeholder="Кратко опишите свою проблему"
                    id="form3Textarea"
                    rows={5}
                    wrapperClass="mb-4"
                    onChange={handleMessageCChange}
                    required
                  />
                  <Row>
                    <Col className="col-12 col-md-6">
                      <Button
                        className={
                          width > 768
                            ? " btn-dark text-first w-75 ms-3 border-0  mb-4"
                            : " btn-dark p-1 text-first w-50 ms-3 border-0  mb-4"
                        }
                        type="submit"
                        onClick={() => AddUser({ nameC, phoneC, messageC })}
                      >
                        Отправить
                      </Button>
                    </Col>
                    <Col className=" d-flex justify-content-start align-items-center">
                      <p
                        className="text-start playful-regular pt-1 pb-4 "
                        style={{
                          marginLeft: width > 500 ? -30 : 20,
                          marginTop: 4,
                        }}
                      >
                        Это бесплатно!
                      </p>
                    </Col>
                  </Row>
                </Row>
              </Container>
            </Col>
            <Col
              className={
                width > 768
                  ? "bg-third border-start rounded-end-5 text-first p-4"
                  : "bg-third border-top rounded-bottom-5 text-first p-4"
              }
            >
              <h2 className="playful-regular">
                Почему вам необходим уход за стопами?
              </h2>
              <p className="playful-regular">
                Забота о ваших ногах является ключевым аспектом общего
                благополучия и подвижности. Ноги несут вес всего нашего тела и
                подвергаются постоянному давлению и стрессу, что делает их
                уязвимыми для различных проблем. Регулярный уход за ногами не
                только предотвращает дискомфорт и боль, но также обеспечивает
                правильную осанку и равновесие, снижая риск падений и травм.
                Помните, стопы - зеркало тела, поэтому важно следить за ними и
                уделять им заслуженное внимание.
              </p>
              <h5 className="playful-regular">
                Заполните форму и получите бесплатную консультацию, которая
                изменит вашу жизнь!
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
const Landing = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div
      className="bg-first overflow-y-hidden "
      style={{ minHeight: width > 932 ? "" : width > 500 ? "175vh" : "250vh" }}
    >
      <Helmet>
        <meta
          name="description"
          content="Комплексный уход и забота о ваших ногах от специалиста-подолога из Волжского со стажем более 7 лет."
        />
        <meta
          name="keywords"
          content="подолог, подолог Волжский, дискомфорт стоп, боль в стопах"
        />
        <link rel="canonical" href="https://podohelp34.ru/help" />
        <title>Забота о ваших ногах - подолог Наталья</title>
      </Helmet>
      <Hero />
    </div>
  );
};

export default Landing;
