import React, { useState, useEffect } from "react";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import hero_3 from "../images/landing-3.jpg";
import hero_4 from "../images/landing-4.jpg";
import hero_5 from "../images/landing-5.jpg";
import hero_6 from "../images/landing-6.jpg";
import review from "../images/review.jpg";
import comfort from "../images/comfort.mov";
import reliability from "../images/reliability.jpg";
import person from "../images/person-short.png";

import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTypography,
  MDBAccordion,
  MDBAccordionItem,
  MDBContainer,
} from "mdb-react-ui-kit";
/**

background : #f0f2f6
higlight: rgba(94, 255, 153, 255)
*/

const Hero = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  if (width > 1100) {
    return (
      <div className="masthead bg-white text-black mt-0" style={{}}>
        <Container
          className="d-flex align-items-start pt-0 justify-content-start h-100 "
          style={{ marginLeft: "5%", paddingRight: "0", maxWidth: width * 0.9 }}
        >
          <Row>
            <Col className="h-100 col-6 overflow-x-visible " style={{}}>
              <h1
                className="gilroy  text-black mt-5 text-start text-nowrap"
                style={{
                  fontWeight: 700,
                  fontSize:
                    width > 1760
                      ? 100
                      : width > 1675
                      ? 95
                      : width > 1600
                      ? 85
                      : width > 1450
                      ? 70
                      : 65,
                }}
              >
                Здоровые стопы
                <br />
                <span className="text-landing">в шаге от вас</span>
              </h1>
              <p
                className="gilroy mt-5 pt-4 "
                style={{
                  fontSize:
                    width > 1760
                      ? 24
                      : width > 1600
                      ? 22
                      : width > 1450
                      ? 20
                      : 19,
                  fontWeight: 500,
                  width: "95%",
                }}
              >
                {" "}
                Эффективные решения проблемам ваших стоп. Вросший ноготь,
                стержневая мазоль, деформация ногтевой пластины и многие другие
                проблемы быстро выявляются и решаются без боли.
              </p>
              <a
                className="btn rounded-3 border-landing bg-transparent text-landing mt-2"
                href="#form"
              >
                <span className="gilroy">Шагнём вместе</span>
              </a>

              <Container
                className="text-start d-flex  ps-0 text-lg-start rounded"
                style={{
                  marginTop: width > 1600 ? "" : 100,
                  width: width > 1600 ? "" : "90vw",
                }}
              >
                <MDBRow className="d-flex justify-content-start border-top mt-3">
                  {" "}
                  <MDBCol md="11">
                    <MDBCard className=" text-black rounded-4 border-0 ">
                      <MDBCardBody className="mt-3">
                        <MDBRow>
                          <MDBCol
                            lg="2"
                            className="d-flex justify-content-start flex-column align-items-start mb-4 mb-lg-0"
                          >
                            <img
                              src={review}
                              className="rounded-circle img-fluid shadow-1"
                              alt="woman avatar"
                              width="100"
                              height="100"
                            />{" "}
                            <p className="fw-bold lead mb-2 ps-3 gilroy">
                              <strong>Мария</strong>
                            </p>
                            <MDBTypography
                              listUnStyled
                              className="d-flex ps-2 justify-content-lg-start justify-content-center  mb-0 text-first"
                            >
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol
                            lg="10"
                            className="d-flex justify-content-start align-items-center "
                          >
                            {" "}
                            <p
                              className="text-black gilroy mb-4 w-100"
                              style={{
                                fontSize: 16,
                              }}
                            >
                              Трудно найти настоящего профессионала, но мне
                              повезло. Попала к Наталье пару лет назад со
                              стержневой мозолью, проблему решила очень быстро и
                              безболезненно. Тогда же я и решила, что маникюр и
                              педикюр только у неё! Гениальная! Невероятная
                              энергетика! Обожаю!
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </Container>
            </Col>
            <Col className="ps-5 ms-5">
              <Container className=" w-100 h-100">
                <Row className="">
                  <Col className=" col-4 mt-5"></Col>
                  <Col className="  mt-5 ms-3">
                    <img
                      alt=""
                      className="rounded-4"
                      src={hero_3}
                      style={{
                        width:
                          width > 1760
                            ? 225.25
                            : width > 1600
                            ? 198.6
                            : width > 1450
                            ? 165.2
                            : 133.3,
                        height:
                          width > 1760
                            ? 250
                            : width > 1600
                            ? 220.2
                            : width > 1450
                            ? 183.2
                            : 147.9,
                      }}
                    ></img>
                  </Col>

                  <Col className=" col-3 mt-5"></Col>
                </Row>
                <Row className="">
                  <Col className=" col-4 me-3">
                    <img
                      alt=""
                      className="rounded-4"
                      src={hero_4}
                      style={{
                        width:
                          width > 1760
                            ? 225.25
                            : width > 1600
                            ? 198.6
                            : width > 1450
                            ? 165.2
                            : 133.3,
                        height:
                          width > 1760
                            ? 337.7
                            : width > 1600
                            ? 297.7
                            : width > 1450
                            ? 247.7
                            : 200,
                        marginTop:
                          width > 1760
                            ? -177.5
                            : width > 1600
                            ? -127.5
                            : width > 1450
                            ? -97.5
                            : -77.5,
                      }}
                    ></img>
                  </Col>
                  <Col className="col-4 me-3">
                    <img
                      alt=""
                      className="rounded-4 mt-5"
                      src={hero_5}
                      style={{
                        width:
                          width > 1760
                            ? 225.25
                            : width > 1600
                            ? 198.6
                            : width > 1450
                            ? 165.2
                            : 133.3,
                        height:
                          width > 1760
                            ? 337.7
                            : width > 1600
                            ? 297.7
                            : width > 1450
                            ? 247.7
                            : 200,
                      }}
                    ></img>
                  </Col>
                  <Col className=" col-3 mt-5">
                    <img
                      alt=""
                      className="rounded-4"
                      src={hero_6}
                      style={{
                        width:
                          width > 1760
                            ? 225.25
                            : width > 1600
                            ? 198.6
                            : width > 1450
                            ? 165.2
                            : 133.3,
                        height:
                          width > 1760
                            ? 337.7
                            : width > 1600
                            ? 297.7
                            : width > 1450
                            ? 247.7
                            : 200,
                        marginTop:
                          width > 1760
                            ? -225.25
                            : width > 1600
                            ? -175.25
                            : -145.25,
                      }}
                    ></img>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="masthead bg-white text-black " style={{}}>
        <Container
          className="d-flex align-items-start pt-0 justify-content-start  "
          style={{ marginLeft: "5%", paddingRight: "0", maxWidth: width * 0.9 }}
        >
          <Row>
            <Col className="h-100 col-12 text-center" style={{}}>
              <h1
                className="gilroy  text-black mt-5 text-center text-nowrap"
                style={{
                  fontWeight: 700,
                  fontSize: width > 576 ? 65 : width > 391 ? 44 : 35,
                  marginLeft: -2,
                }}
              >
                Здоровые стопы
                <br />
                <span className="text-landing">в шаге от вас</span>
              </h1>
              <p
                className="gilroy mt-md-2 mt-2 pt-4 text-center"
                style={{
                  fontSize: width > 576 ? 19 : 16,
                  fontWeight: 500,
                  width: "100%",
                  paddingRight: width > 500 ? "12.5%" : "6.5%",
                  paddingLeft: width > 500 ? "12.5%" : "6.5%",
                }}
              >
                {" "}
                Эффективные решения проблемам ваших стоп. Вросший ноготь,
                стержневая мазоль, деформация ногтевой пластины и многие другие
                проблемы быстро выявляются и решаются без боли.
              </p>
              <a
                className="btn rounded-3 border-landing bg-transparent text-landing mt-2"
                href="#form"
              >
                <span
                  className="gilroy"
                  style={{ fontSize: width > 576 ? 19 : 14 }}
                >
                  Шагнём вместе
                </span>
              </a>
              <Col className="mt-4">
                <Container className=" w-100 h-100">
                  <Row className="d-flex justify-content-center ">
                    <Col
                      className="col-3 me-sm-4 "
                      style={{ display: width > 392 ? "block" : "none" }}
                    >
                      <img
                        alt=""
                        className="rounded-4 mt-4"
                        src={hero_5}
                        style={{
                          width:
                            width > 768 ? 171.9 : width > 576 ? 125.2 : 85.1,
                          height:
                            width > 768 ? 257.7 : width > 576 ? 187.7 : 127.7,
                        }}
                      ></img>
                    </Col>
                    <Col
                      className={
                        width > 392 ? " col-4 me-sm-4 me-2" : "col-12 ms-1"
                      }
                      style={{ marginLeft: width > 392 ? "" : -12 }}
                    >
                      <img
                        alt=""
                        className="rounded-4"
                        src={hero_4}
                        style={{
                          width:
                            width > 768
                              ? 225.25
                              : width > 576
                              ? 171.9
                              : width > 392
                              ? 125.5
                              : 225.25,
                          height:
                            width > 768
                              ? 337.7
                              : width > 576
                              ? 257.7
                              : width > 392
                              ? 187.7
                              : 337.7,
                        }}
                      ></img>
                    </Col>
                    <Col
                      className=" col-3"
                      style={{ display: width > 392 ? "block" : "none" }}
                    >
                      <img
                        alt=""
                        className="rounded-4 mt-4"
                        src={hero_6}
                        style={{
                          width:
                            width > 768 ? 171.9 : width > 576 ? 125.2 : 85.1,
                          height:
                            width > 768 ? 257.7 : width > 576 ? 187.7 : 127.7,
                        }}
                      ></img>
                    </Col>
                  </Row>
                </Container>
              </Col>

              <Container
                className="text-start d-flex  ps-0 text-lg-start rounded"
                style={{
                  width: "90vw",
                  marginLeft: -16,
                }}
              >
                <MDBRow
                  className="d-flex justify-content-start border-top mt-3 "
                  style={{ width: "100vw" }}
                >
                  {" "}
                  <MDBCol md="11">
                    <MDBCard className=" text-black rounded-4 border-0  ">
                      <MDBCardBody className="mt-3 p-0">
                        <MDBRow>
                          <MDBCol
                            lg="2"
                            className="d-flex justify-content-start justify-content-sm-start flex-column align-items-sm-start align-items-start mb-4 mb-lg-0 col-sm-2 col-10"
                          >
                            <img
                              src={review}
                              className="rounded-circle img-fluid shadow-1"
                              alt="woman avatar"
                              width={width > 475 ? "100" : "70"}
                              height={width > 475 ? "100" : "70"}
                            />
                            <p className="fw-bold lead mb-2 ps-lg-3 ps-sm-0 ps-0 text-sm-center text-lg-start w-100  gilroy">
                              <strong>Мария</strong>
                            </p>
                            <MDBTypography
                              listUnStyled
                              className="d-flex ps-lg-2 ps-sm-0 ps-0 w-100 justify-content-lg-start justify-content-sm-center  mb-0 text-first"
                            >
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                              <li>
                                <MDBIcon
                                  fas
                                  icon="star"
                                  size="sm"
                                  style={{ color: "#17a10a" }}
                                  className=""
                                />
                              </li>
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol
                            lg="10"
                            className="d-flex justify-content-start align-items-start col-10 "
                          >
                            {" "}
                            <p
                              className="text-black ms-md-2 gilroy mb-4"
                              style={{
                                fontSize: width > 576 ? 17 : 16,
                                width: " 90vw",
                              }}
                            >
                              Трудно найти настоящего профессионала, но мне
                              повезло. Попала к Наталье пару лет назад со
                              стержневой мозолью, проблему решила очень быстро и
                              безболезненно. Тогда же я и решила, что маникюр и
                              педикюр только у неё! Гениальная! Невероятная
                              энергетика! Обожаю!
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

const Comfort = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div className="mt-5 mb-5 ms-0">
      <Container
        className={
          width > 1200 ? "" : width > 1100 ? "ms-4 ps-4" : " ms-0 ps-0"
        }
      >
        <Row
          className={
            width >= 768
              ? "d-flex flex-nowrap justify-content-center "
              : " d-flex  "
          }
          style={{
            width: width >= 768 && width < 1200 ? "94vw" : "",
            marginLeft: width >= 768 && width < 1200 ? "3vw" : 0,
          }}
        >
          <Col
            className={width >= 768 ? "col-6" : "col-12 p-0"}
            style={{
              width:
                width >= 1400
                  ? 700
                  : width >= 1200
                  ? 620
                  : width >= 992
                  ? 570
                  : width >= 768
                  ? 470
                  : "94vw",
              maxWidth: width < 768 ? "94vw" : "",
              marginLeft: width < 768 ? "3vw" : "",
            }}
          >
            <video
              width={
                width >= 1400
                  ? "650"
                  : width >= 1200
                  ? "570"
                  : width >= 992
                  ? "520"
                  : width >= 768
                  ? "420"
                  : "100%"
              }
              height={
                width >= 1400
                  ? "365"
                  : width >= 1200
                  ? "321"
                  : width >= 992
                  ? "293"
                  : width >= 768
                  ? "236"
                  : ""
              }
              autoPlay
              loop
              playsInline
              muted
              className="rounded-5"
              style={{
                marginTop:
                  width > 1100
                    ? ""
                    : width >= 992
                    ? 25
                    : width >= 768
                    ? 47
                    : "",
              }}
            >
              <source src={comfort} type="video/mp4" />
            </video>
          </Col>
          <Col
            className=" col-12 col-lg-6 p-0  "
            style={{
              maxWidth: width >= 768 ? "43vw" : "94vw",
              marginLeft: width < 768 ? "3vw" : "",
            }}
          >
            <h2
              className={
                width >= 768 && width < 992
                  ? "gilroy  text-black text-start text-nowrap"
                  : width < 768
                  ? width < 300
                    ? "gilroy  text-black text-start mt-4 text-wrap"
                    : "gilroy  text-black text-start mt-4 text-nowrap"
                  : "gilroy  text-black text-start "
              }
              style={{
                fontWeight: 700,
                fontSize:
                  width > 1400
                    ? 50
                    : width > 1200
                    ? 40
                    : width >= 992
                    ? 32
                    : width >= 768
                    ? 26
                    : width >= 414
                    ? 26
                    : 22,
                marginLeft: width >= 992 ? "" : width >= 768 ? "" : "",
                width: "",
                maxWidth: 810,
              }}
            >
              <span className="text-landing">Наслаждайтесь</span> жизнью.
              <br />
              <span className={width < 300 ? "" : " text-nowrap "}>
                Ваши стопы - моя забота
              </span>
            </h2>
            <p
              className="text-black gilroy text-start mt-0  "
              style={{
                fontWeight: 500,
                fontSize:
                  width > 1400
                    ? 20
                    : width > 1200
                    ? 18
                    : width >= 992
                    ? 17
                    : width >= 768
                    ? 16
                    : width >= 414
                    ? 17
                    : 17,
                width: width > 992 ? "90%" : width >= 768 ? "95%" : "95vw",
                maxWidth: width >= 768 ? 600 : "98vw",
              }}
            >
              Дарю Вашим стопам и ногтям второе дыхание! Забудьте о деформациях
              и дискомфорте, ведь теперь у вас есть я – мастер с 7-летним опытом
              успешной работы и сотнями довольных клиентов.
            </p>
            <p
              className="text-black gilroy text-start mt-1"
              style={{
                fontWeight: 500,

                fontSize:
                  width > 1400
                    ? 20
                    : width > 1200
                    ? 18
                    : width >= 992
                    ? 17
                    : width >= 768
                    ? 16
                    : width >= 414
                    ? 17
                    : 17,
                width: width > 992 ? "40vw" : width >= 768 ? "35vw" : "96vw",
                maxWidth: width >= 768 ? 600 : "98vw",
              }}
            >
              Летайте на море, купайтесь в бассейне и гордитесь своими стопами.
              Доверьтесь мне и увидите результаты.
            </p>
            <a
              className={
                width > 1400
                  ? "btn rounded-3 border-landing bg-transparent text-landing mt-2"
                  : "btn rounded-3 border-landing bg-transparent text-landing "
              }
              style={{ marginTop: 11 }}
              href="#form"
            >
              <span className="gilroy">Попробуйте бесплатно</span>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Reliability = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div className="mt-5 mb-5 ms-0">
      <Container
        className={
          width > 1200 ? "" : width > 1100 ? "ms-4 ps-4" : " ms-0 ps-0"
        }
      >
        <Row
          className={
            width >= 768
              ? "d-flex flex-nowrap justify-content-center "
              : " d-flex  "
          }
          style={{
            width: width >= 768 && width < 1200 ? "94vw" : "",
            marginLeft: width >= 768 && width < 1200 ? "3vw" : 0,
          }}
        >
          <Col
            className=" col-12 d-md-none  d-block p-0  "
            style={{
              width:
                width >= 1400
                  ? 700
                  : width >= 1200
                  ? 620
                  : width >= 992
                  ? 570
                  : width >= 768
                  ? 470
                  : "94vw",
              maxWidth: width < 768 ? "94vw" : "",
              marginLeft: width < 768 ? "3vw" : "",
            }}
          >
            <img
              alt=""
              src={reliability}
              style={{ width: "100%" }}
              className="rounded-5"
            ></img>
          </Col>
          <Col
            className={width >= 768 ? "col-6" : "col-12 p-0"}
            style={{
              width:
                width >= 1400
                  ? 700
                  : width >= 1200
                  ? 620
                  : width >= 992
                  ? 570
                  : width >= 768
                  ? 470
                  : "94vw",
              maxWidth: width < 768 ? "94vw" : "",
              marginLeft: width < 768 ? "3vw" : "",
            }}
          >
            <h2
              className={"gilroy  text-black text-start mb-2"}
              style={{
                fontWeight: 700,
                fontSize:
                  width > 1400
                    ? 50
                    : width > 1200
                    ? 40
                    : width >= 992
                    ? 32
                    : width >= 768
                    ? 26
                    : width >= 414
                    ? 26
                    : 22,
                width: "",
                maxWidth: 810,
              }}
            >
              <span className=" text-nowrap ">Испытание</span>

              <span className="text-landing"> временем</span>
            </h2>
            <p
              className="text-black gilroy text-start mt-0"
              style={{
                fontWeight: 500,
                fontSize:
                  width > 1400
                    ? 20
                    : width > 1200
                    ? 18
                    : width >= 992
                    ? 17
                    : width >= 768
                    ? 16
                    : width >= 414
                    ? 17
                    : 17,
                width: width > 992 ? "90%" : width >= 768 ? "95%" : "95vw",
                maxWidth: width >= 768 ? 600 : "98vw",
              }}
            >
              Все исправленные мною дефекты проходят испытание временем. После
              моих приемов вам не нужно будет беспокоиться о скором возвращении
              в мой кабинет.
            </p>
            <p
              className="text-black gilroy text-start mt-1"
              style={{
                fontWeight: 500,
                fontSize:
                  width > 1400
                    ? 20
                    : width > 1200
                    ? 18
                    : width >= 992
                    ? 17
                    : width >= 768
                    ? 16
                    : width >= 414
                    ? 17
                    : 17,
                width: width > 992 ? "90%" : width >= 768 ? "95%" : "95vw",
                maxWidth: width >= 768 ? 600 : "98vw",
              }}
            >
              Когда все проблемы будут устранены, вам не придется ограничивать
              себя - можно будет всё: и бассейн, и море, и побегать по песку, не
              боясь повредить стопы!
            </p>
            <a
              href="#form"
              className="btn rounded-3 border-landing bg-transparent text-landing mt-2"
            >
              <span className="gilroy">Шагнем к совершенсту вместе</span>
            </a>
          </Col>
          <Col
            className=" col-6 d-md-flex align-items-center d-none p-0  "
            style={{
              maxWidth: width >= 768 ? "43vw" : "94vw",
              marginLeft: width < 768 ? "3vw" : "",
            }}
          >
            <img
              alt=""
              src={reliability}
              style={{
                maxWidth: width > 1500 ? 650 : width >= 1200 ? "43vw" : "39vw",
              }}
              className="rounded-5"
            ></img>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Price = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div className="mt-4">
      <Container
        className={
          width > 1200 ? "" : width > 1100 ? "ms-4 ps-4" : " ms-0 ps-0"
        }
      >
        <Row
          className="d-flex flex-nowrap "
          style={{
            width: width < 1200 ? "94vw" : "",
            marginLeft: width < 1200 ? "3vw" : 0,
          }}
        >
          <Col className="col-12 w-100">
            <h2
              className={
                width >= 530
                  ? "gilroy  text-black text-center mb-5"
                  : "gilroy  text-black text-start mb-5"
              }
              style={{
                fontWeight: 700,
                fontSize:
                  width > 1400
                    ? 50
                    : width > 1200
                    ? 40
                    : width >= 992
                    ? 32
                    : width >= 768
                    ? 26
                    : width >= 414
                    ? 26
                    : 22,
              }}
            >
              <span className="text-landing">Низкие</span>
              <span className=" text-nowrap "> цены</span>.
              {width < 560 ? <br /> : <></>}Профессиональные услуги
            </h2>
            <p
              className={
                width >= 530
                  ? "text-black gilroy text-center mt-0"
                  : "text-black gilroy text-start mt-0"
              }
              style={{
                fontWeight: 500,
                fontSize:
                  width > 1400
                    ? 20
                    : width > 1200
                    ? 18
                    : width >= 992
                    ? 17
                    : width >= 768
                    ? 16
                    : width >= 414
                    ? 17
                    : 17,
                width: width > 1200 ? "100%" : width >= 768 ? "90%" : "100%",
                maxWidth: width >= 768 ? "98vw" : "98vw",
                paddingLeft: width > 1200 ? "" : width >= 768 ? "10%" : "",
              }}
            >
              Современное оборудование и авторские техники позволяют мне
              выполнять работу быстро и за небольшую цену. Более 7-ми лет
              успешной работы научили меня лучшим способам решения ваших
              проблем.
            </p>
            {width > 520 ? (
              <>
                <div className="d-flex flex-row justify-content-between">
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                      className="text-center gilroy"
                    >
                      Корректировка ногтевой пластины
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                    >
                      2000р <span> 4000р</span>
                    </p>
                  </div>
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                      className="text-center gilroy"
                    >
                      Гигиенический педикюр
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                    >
                      1500р
                    </p>
                  </div>
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                      className="text-center gilroy"
                    >
                      Парамедецинский педикюр
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                    >
                      2000р+
                    </p>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between mt-1">
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                      className="text-center gilroy"
                    >
                      Обработка стержневой мазоли
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                    >
                      500р
                    </p>
                  </div>
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                      className="text-center gilroy"
                    >
                      Зачистка ногтя
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                    >
                      500р
                    </p>
                  </div>
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                      className="text-center gilroy"
                    >
                      Мужской педикюр
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 16,
                      }}
                    >
                      2000р
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-row justify-content-between">
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                      className="text-center gilroy"
                    >
                      Корректировка ногтевой пластины
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                    >
                      2000р <span> 4000р</span>
                    </p>
                  </div>
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                      className="text-center gilroy"
                    >
                      Гигиенический <br />
                      педикюр
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                    >
                      1500р
                    </p>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between mt-1">
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                      className="text-center gilroy"
                    >
                      Парамедецинский педикюр
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                    >
                      2000р+
                    </p>
                  </div>
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                      className="text-center gilroy"
                    >
                      Обработка стержневой мазоли
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                    >
                      500р
                    </p>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between mt-1">
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                      className="text-center gilroy"
                    >
                      Зачистка ногтя
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                    >
                      500р
                    </p>
                  </div>
                  <div
                    className="  text-landing"
                    style={{ width: 350, height: 100 }}
                  >
                    <h3
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                      className="text-center gilroy"
                    >
                      Мужской педикюр
                    </h3>
                    <p
                      className="gilroy d-flex flex-row justify-content-around "
                      style={{
                        fontSize:
                          width > 1400
                            ? 18
                            : width > 1200
                            ? 17
                            : width >= 992
                            ? 16
                            : width >= 768
                            ? 15
                            : width >= 414
                            ? 16
                            : 14,
                      }}
                    >
                      2000р
                    </p>
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Master = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div className="mt-5 mb-5 ms-0">
      <Container
        className={
          width > 1200 ? "" : width > 1100 ? "ms-4 ps-4" : " ms-0 ps-0"
        }
      >
        <Row
          className={
            width >= 768
              ? "d-flex flex-nowrap justify-content-center "
              : " d-flex  "
          }
          style={{
            width: width >= 768 && width < 1200 ? "94vw" : "",
            marginLeft: width >= 768 && width < 1200 ? "3vw" : 0,
          }}
        >
          <Col
            className={width >= 768 ? "col-6" : "col-12 p-0"}
            style={{
              width:
                width >= 1400
                  ? 700
                  : width >= 1200
                  ? 620
                  : width >= 992
                  ? 570
                  : width >= 768
                  ? 470
                  : "94vw",
              maxWidth: width < 768 ? "94vw" : "",
              marginLeft: width < 768 ? "3vw" : "",
            }}
          >
            <img
              alt=""
              src={person}
              style={{
                width:
                  width >= 1400
                    ? 650
                    : width >= 1200
                    ? 570
                    : width >= 992
                    ? 520
                    : width >= 768
                    ? 420
                    : "100%",
                height:
                  width >= 1400
                    ? 425
                    : width >= 1200
                    ? 373
                    : width >= 992
                    ? 340
                    : width >= 768
                    ? 275
                    : "",
              }}
              className="rounded-5"
            ></img>
          </Col>
          {/**
              style={{
                width:
                  width >= 1400
                    ? 650
                    : width >= 1200
                    ? 570
                    : width >= 992
                    ? "520"
                    : width >= 768
                    ? "420"
                    : "100%",
                height:
                  width >= 1400
                    ? "365"
                    : width >= 1200
                    ? "321"
                    : width >= 992
                    ? "293"
                    : width >= 768
                    ? "236"
                    : "" }} */}
          <Col
            className=" col-12 col-lg-6 p-0  "
            style={{
              maxWidth: width >= 768 ? "43vw" : "94vw",
              marginLeft: width < 768 ? "3vw" : "",
            }}
          >
            <h2
              className={
                width >= 768 && width < 992
                  ? "gilroy  text-black text-start text-nowrap"
                  : width < 768
                  ? width < 300
                    ? "gilroy  text-black text-start mt-4 text-wrap"
                    : "gilroy  text-black text-start mt-4 text-nowrap"
                  : "gilroy  text-black text-start "
              }
              style={{
                fontWeight: 700,
                fontSize:
                  width > 1400
                    ? 50
                    : width > 1200
                    ? 40
                    : width >= 992
                    ? 32
                    : width >= 768
                    ? 26
                    : width >= 414
                    ? 26
                    : 22,
                marginLeft: width >= 992 ? "" : width >= 768 ? "" : "",
                width: "",
                maxWidth: 810,
              }}
            >
              <span className=" text-nowrap ">Ваш </span>
              <span className="text-landing"> мастер</span> - <br />
              Наталья Глухова
            </h2>
            <p
              className="text-black gilroy text-start mt-0  "
              style={{
                fontWeight: 500,
                fontSize:
                  width > 1400
                    ? 20
                    : width > 1200
                    ? 18
                    : width >= 992
                    ? 17
                    : width >= 768
                    ? 16
                    : width >= 414
                    ? 17
                    : 17,
                width: width > 992 ? "90%" : width >= 768 ? "95%" : "95vw",
                maxWidth: width >= 768 ? 600 : "98vw",
              }}
            >
              Я не только имею большой опыт работы, но и являюсь активным
              преподавателем института подологии.
            </p>
            <p
              className="text-black gilroy text-start mt-1"
              style={{
                fontWeight: 500,

                fontSize:
                  width > 1400
                    ? 20
                    : width > 1200
                    ? 18
                    : width >= 992
                    ? 17
                    : width >= 768
                    ? 16
                    : width >= 414
                    ? 17
                    : 17,
                width: width > 992 ? "90%" : width >= 768 ? "95%" : "95vw",
                maxWidth: width >= 768 ? 600 : "98vw",
              }}
            >
              У меня есть все необходимое для того, чтобы вам было комфортно и
              безопасно: чистый кабинет, аппарат работы со спреем, стерильные
              инструменты и профессиональная космецевтика.
            </p>
            <a
              className={
                width > 1400
                  ? "btn rounded-3 border-landing bg-transparent text-landing mt-2"
                  : "btn rounded-3 border-landing bg-transparent text-landing "
              }
              style={{ marginTop: 11 }}
              href="#form"
            >
              <span className="gilroy">Обратиться за помощью</span>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const FAQ = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div className="mb-5">
      <Container>
        <h2
          className={"gilroy  text-black text-center mb-5"}
          style={{
            fontWeight: 700,
            fontSize:
              width > 1400
                ? 50
                : width > 1200
                ? 40
                : width >= 992
                ? 32
                : width >= 768
                ? 26
                : width >= 414
                ? 26
                : 22,
          }}
        >
          Частые вопросы
        </h2>
        <MDBContainer className="mt-5" style={{ maxWidth: "1000px" }}>
          <MDBAccordion alwaysOpen>
            <MDBAccordionItem
              collapseId={1}
              headerClassName="gilroy"
              className="gilroy"
              headerTitle="Используемое оборудование"
            >
              <strong>Кабинет оснащён современным оборудованием.</strong> Кресло
              очень комфортное и учитывает все ваши анатомичечкие особенности.
              Есть аппарат с функцией подачи воды. Стерилизатор - автоклав
              класса В. Мои инструменты стерильные, как у хирурга.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerClassName="gilroy"
              className="gilroy"
              headerTitle="Насколько будет больно?"
            >
              Я знаю, что вы чувствуете, потому что сама это пережила. Я
              понимаю, что нужно делать и{" "}
              <strong>
                делаю так, чтобы было не больно, аккуратно и действенно.
              </strong>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerClassName="gilroy"
              className="gilroy"
              headerTitle="Сколько времени уйдет на решение проблемы?"
            >
              <strong>Зависит от особенностей вашей проблемы.</strong>{" "}
              Записывайтесь на консультацию или звоните по телефону + 7 937 704
              66 66, чтобы узнать сколько времени уйдёт на решение.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerClassName="gilroy"
              className="gilroy"
              headerTitle="Мой опыт"
            >
              <strong>Более 7 лет успешной работы.</strong> Я являюсь активным
              преподавателем института подологи, узнать больше можно по{" "}
              <a
                className="gilroy text-decoration-none "
                href="https://podo.clinic/company/staff/natalya-vladimirovna-glukhova/"
              >
                ссылке
              </a>
              .
            </MDBAccordionItem>
          </MDBAccordion>
        </MDBContainer>
      </Container>
    </div>
  );
};

async function AddUser({ nameC, phoneC, messageC }) {
  await axios.post(`api/users`, {
    username: nameC,
    phone: phoneC,
    form_message: messageC,
  });
  window.location.reload(false);
}

const CTA = () => {
  const [nameC, setNameC] = useState("");

  const handleNameCChange = (event) => {
    setNameC(event.target.value);
  };
  //PhoneCState
  const [phoneC, setPhoneC] = useState("");

  const handlePhoneCChange = (event) => {
    setPhoneC(event.target.value);
  };
  //MessageCState
  const [messageC, setMessageC] = useState("");

  const handleMessageCChange = (event) => {
    setMessageC(event.target.value);
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div className="mb-5" id="form">
      <Container>
        <Form className="d-flex flex-column justify-content-center align-items-center">
          <h2
            className={"gilroy  text-black text-center mb-5"}
            style={{
              fontWeight: 700,
              fontSize:
                width > 1400
                  ? 50
                  : width > 1200
                  ? 40
                  : width >= 992
                  ? 32
                  : width >= 768
                  ? 26
                  : width >= 414
                  ? 26
                  : 20,
            }}
          >
            Обменяете свой номер телефона <br /> на{" "}
            <span className="text-landing">бесплатную</span> консультацию?
          </h2>
          <Form.Group
            className={width >= 768 ? "mb-3 w-50" : "mb-3 w-75"}
            controlId="formBasicEmail"
          >
            <Form.Label className="text-black gilroy ms-0 fs-5">
              Ваш телефон*
            </Form.Label>
            <Form.Control
              type="phone"
              className="gilroy"
              placeholder="Введите номер телефона"
              onChange={handlePhoneCChange}
            />
          </Form.Group>

          <Form.Group
            className={width >= 768 ? "mb-3 w-50" : "mb-3 w-75"}
            controlId="formBasicEmail"
          >
            <Form.Label className="text-black gilroy ms-0 fs-5">
              Ваше имя*
            </Form.Label>
            <Form.Control
              type="text"
              className="gilroy"
              placeholder="Введите ваше имя"
              onChange={handleNameCChange}
            />
          </Form.Group>
          <Form.Group
            className={width >= 768 ? "mb-3 w-50" : "mb-3 w-75"}
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label className="text-black gilroy ms-0 fs-5">
              Как я могу вам помочь?
            </Form.Label>
            <Form.Control
              className="gilroy"
              as="textarea"
              rows={3}
              onChange={handleMessageCChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            className=" bg-landing border-0"
            onClick={() => AddUser({ nameC, phoneC, messageC })}
          >
            <span className="gilroy">Отправить</span>
          </Button>
        </Form>
      </Container>
    </div>
  );
};
const Landing = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div
      style={{ background: "#fff" }}
      className="d-flex flex-column justify-content-center "
    >
      <h2
        className="text-black w-100 text-center gilroy mt-4"
        style={{
          fontWeight: 700,
          fontSize:
            width > 1400
              ? 50
              : width > 1200
              ? 40
              : width >= 992
              ? 32
              : width >= 768
              ? 26
              : width >= 414
              ? 26
              : 20,
        }}
      >
        +7 937 704 <span className="text-landing">66 66</span>
      </h2>
      <Hero />
      <Comfort />
      <Reliability />
      <Price />
      <Master />
      <FAQ />
      <CTA />
    </div>
  );
};

export default Landing;
